import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/default-page-layout.js";
import ReadingListProgress from 'components/ReadingListProgress';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`A list of books I have read, or would like to read. I believe in a healthy mix of fiction and non-fiction.`}</p>
    <ReadingListProgress total={115} read={73} mdxType="ReadingListProgress" />
    <h2>{`History`}</h2>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Unbroken - Laura Hillenbrand`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Thomas Jefferson and the Tripoli Pirates - Brian Kilmeade`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`1776 - David McCullough`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`The Decline and Fall of the Roman Empire - Edward Gibbon`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Foxes Book of Martyrs - John Foxe`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Church History in Plain Language - Bruce Shelley`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Ghost Soldiers - Hampton Sides`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Patton: Genius for War - Carlo D'Este`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Black Hawk Down - Mark Bowden`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`John Adams - David McCullough`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Washington: A Life - Ron Chernow`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`God of Liberty: A Religious History of the American Revolution - Thomas Kidd`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`God's War: A New History of the Crusades`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`God's Battalions: The Case for the Crusades`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Warriors of God: Richard the Lionheart & Saladin in the 3rd Crusade`}</li>
    </ul>
    <h2>{`Masculinity, Femininity and Family`}</h2>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Wild at Heart - John Eldridge`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`The Book of Man - William J. Bennett`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Recovering Biblical Manhood and Womanhood`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`The Five Love Languages - Gary Chapman`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Raising Men: Lessons Navy SEALs Learned from Their Training and Taught to Their Sons - Eric Davis, Dina Santorelli`}</li>
    </ul>
    <h2>{`Finances`}</h2>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Financial Peace - Dave Ramsey`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`The Debt Consolidation Myth - Jesse Mecham `}<a parentName="li" {...{
          "href": "https://youneedabudget.com"
        }}>{`YNAB`}</a></li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Invest Like a Pro - Jesse Mecham `}<a parentName="li" {...{
          "href": "https://youneedabudget.com"
        }}>{`YNAB`}</a></li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Biblical Economics - R.C. Sproul Jr.`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`The Total Money Makeover - Dave Ramsey`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`PACE: A Small Business Owner's Guide to Complete Cash Flow Clarity - Jesse Mecham`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`The RESP Book: The Simple Guide to Registered Education Savings Plans for Canadians - Mike Holman`}</li>
    </ul>
    <h2>{`Nutrition`}</h2>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Wired to Eat - Robb Wolf`}</li>
    </ul>
    <h2>{`Philosophy`}</h2>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Francis Schaeffer Trilogy`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`C.S. Lewis Collection`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Mere Christianity - C.S. Lewis`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Ravi Zacharias`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`On Guard - William Lane Craig`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`The Design of Life - William Dembski`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Truth Project DVD Series`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Pendulum: Where We've Been, How We Got There, Where We're Headed - Roy H. Williams, Michael R. Drew`}</li>
    </ul>
    <h2>{`Theology`}</h2>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`ESV Study Bible - God`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`ESV Reference Bible`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Systematic Theology - Wayne Grudem`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`They Found the Secret - V. Raymond Edman`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Creed or Chaos? - Dorothy L. Sayers`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`The Waning Authority of Christ in the Churches - A.W. Tozer`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Muscular Christianity - Brett & Kate McKay`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Why Men Hate Going to Church - David Murrow`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`God's Final Word: Understanding Revelation - Ray C. Stedman, James D. Denney`}</li>
    </ul>
    <h2>{`Personal Development`}</h2>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Psycho-cybernetics - Maxwell Maltz`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Man Up - Bedros Keuilian `}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Greenlights - Matthew McConaughey`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Ten Steps Toward Christ - Jimmy Evans`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`The Checklist Manifesto - Atul Gawande`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Make Your Bed - Admiral William H. McRaven`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Spirit Filled Jesus - Mark Driscoll`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`EntreLeadership - Dave Ramsey`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Start With Why - Simon Sinek`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Called to Create - Jordan Raynor ⭐`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`The 7 Habits of Highly Effective People - Steven R. Covey`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`If You're Going Through Hell, Keep Going! - Doug Giles`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`How to be Filled with the Holy Spirit - A.W. Tozer`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Total Commitment to Christ - A.W. Tozer`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Extreme Ownership - Jocko Willink & Leif Babin`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`The Perfect Day Formula - Craig Ballatyne ⭐`}</li>
    </ul>
    <h2>{`Fiction`}</h2>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Ben-Hur - Lew Wallace`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`William Shakespeare Complete Works`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Sir Knight of the Splendid Way - W.E. Clue`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Pilgrims Progress - John Bunyan`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Space Trilogy - C.S. Lewis`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Manalive - G.K. Chesterton`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`1984 - George Orwell`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Chronicles of Narnia - C.S. Lewis`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Picture of Dorian Gray - Oscar Wilde`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Count of Monte Cristo - Alexandre Dumas`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Les Miserables - Victor Hugo`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Lord of the Rings - J.R. Tolkien`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Sword of Truth Series - Terry Goodkind`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Forgotten Realms - R.A. Salvatore`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`The Kingkiller Chronicles - Patrick Rothfuss`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Miss Peregrine's Home for Peculiar Children - Ransom Riggs`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Tom Clancy Splinter Cell Series`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`The Lost World - Arthur Conan Doyle`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`The Road - Cormac McCarthy`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Mark of the Lion Series - Francine Rivers`}</li>
    </ul>
    <h2>{`Inspiration`}</h2>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Alone Against the North - Adam Shoalts`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Beyond the Trees - Adam Shoalts`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Run the Mile You're In - Ryan Hall`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Microadventures - Alastair Humphreys`}</li>
    </ul>
    <h2>{`Marketing`}</h2>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Secret Formulas of the Wizard of Ads - Roy H. Williams`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`The Wizard of Ads: Turning Words into Magic and Dreamers into Millionairs - Roy H. Williams`}</li>
    </ul>
    <h2>{`Biographies`}</h2>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Perfectly Wounded - Rober W. Vera`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Seeking Allah, Finding Jesus - Nabeel Qureshi`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Out of the Devil's Cauldron - John Ramirez`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Sea Stories - Admiral William H. McRaven`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Seal of God - Chad Williams`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Code Name: Johnny Walker - Johnny Walker, Jim DeFelice`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Fearless - Eric Blehm ⭐`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Into Thin Air - Jon Krakauer`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Damn Few - Rourke Denver`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Seal Team Six - Howard E. Wasdin & Stephen Templin`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Lone Survivor - Marcus Luttrell`}</li>
    </ul>
    <h2>{`Fighter Mindset`}</h2>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Principles of Personal Defense - Jeff Cooper`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Left of Bang - Patrick Von Horne, Jason A. Riley`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Fighting Smarter - Tom Givens`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Deadly Force - Massad Ayoob`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`On Combat - Dave Grossman`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`On Killing - Dave Grossman`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`The Gift of Fear - Gavin de Becker`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Leadership and Training for the Fight - Paul Howe`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Leadership in the Shadows - Kyle Lamb`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`The Art of War - Sun Tzu`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Tides of War - Stephen Pressfield`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Gates of Fire - Stephen Pressfield`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Virtues of War - Stephen Pressfield`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`The Warrior Ethos - Stephen Pressfield`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`The Prince - Niccolo Machiavelli`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Escape the Wolf - Clint Emerson`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`The Right Kind of Crazy - Clint Emerson`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`100 Deadly Skills - Clint Emerson`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      